
<template>
	<transition
		name="bubble-ups-up"
		mode="in-out"
		appear
	>
		<div
			v-if="myUpsMessageType === 'text'"
			class="bubble bubble-black bubble-ups"
		>
			<div v-html="myUpsMessageText"></div>
			<span
				class="learn-more"
			>
				{{myUpsMessageCurrentNumberOfMessages}}/{{myUpsMessageTotalNumberOfMessages}}
			</span>
		</div>

		<router-link
			:to="'/'"
			v-else-if="myUpsMessageType === 'link'"
			class="bubble bubble-black bubble-ups"
		>
			<span>{{ myUpsMessageText }}</span>
			<br>
			<span
				v-if="myUpsMessageLinkText"
				class="learn-more"
			>{{ myUpsMessageLinkText }}</span>
		</router-link>

		<div
			v-else
			class="bubble bubble-black bubble-ups"
		>
			<!-- <span class="learn-more">
				Klick to read again…
			</span> -->
			…
		</div>
	</transition>
</template>

<script>
	import throttle from 'lodash/throttle'

	export default {
		data() {
			return {
				initialScrollPosition: 0
			}
		},

		props: {
			lastClickX: {
				type: Number,
				required: true
			},
			lastClickY: {
				type: Number,
				required: true
			},
			upsMessage: {
				type: Object,
				required: false
			}
		},

		computed: {
			myUpsMessageType () {
				if (!this.upsMessage.currentUpsMessage) return
				return this.upsMessage.currentUpsMessage.type
			},
			myUpsMessageText () {
				if (!this.upsMessage.currentUpsMessage) return
				return this.upsMessage.currentUpsMessage.text
			},
			myUpsMessageLinkText () {
				if (!this.upsMessage.currentUpsMessage) return
				return this.upsMessage.currentUpsMessage.linkText
			},
			myUpsMessageCurrentNumberOfMessages () {
				if (!this.upsMessage) return
				return this.upsMessage.numberOfCurrentMessage + 1
			},
			myUpsMessageTotalNumberOfMessages () {
				if (!this.upsMessage) return
				return this.upsMessage.totalNumberOfMessages
			}
		},

		methods: {
			parallaxFunction () {
				if (this.initialScrollPosition === 0) {
					this.initialScrollPosition = this.$el.getBoundingClientRect().top
				}
				let scrolled = this.initialScrollPosition - this.$el.getBoundingClientRect().top

				const parallaxTarget = this.$el
				const offset = (scrolled * -0.035) + 'px'
				parallaxTarget.style.transform = `translateY(${offset})`
			}
		},

		mounted() {
			window.addEventListener('scroll', throttle(this.parallaxFunction, 20), { passive: true })


			// If clicked somewhere on the right side of the screen, the message appears left from the click and vice versa
			if (this.lastClickX > (window.innerWidth / 2)) {
				let offsetRight = window.innerWidth - this.lastClickX
				this.$el.style.right = `calc(${offsetRight}px - 10px)`;
			} else {
				this.$el.style.left = `${this.lastClickX}px`;
			}

			// If clicked somewhere on the top of the screen, the message appears belowe the click position and vice versa
			if (this.lastClickY > (window.innerHeight / 4)) {
				// 5px optical allignment
				this.$el.style.top = `calc(${this.lastClickY}px - ${this.$el.offsetHeight}px - 10px)`;
			} else {
				this.$el.style.top = `calc(${this.lastClickY}px - 10px)`;
			}
		},

		destroyed () {
			window.removeEventListener('scroll', throttle(this.parallaxFunction, 20), { passive: true })
		},
	}
</script>

<style lang="scss">

	@import '../assets/css/variables.css';

	.bubble-ups {
		position: absolute;
		opacity: 1;
		margin: calc(var(--border-radius) * .5);
		
		user-select: none;

		// &:after {
		// 	content: "";
		// 	position: absolute;
		// 	left: calc(var(--border-radius) * 1.25);
		// 	bottom: 0;
		// 	width: 0;
		// 	height: 0;
		// 	border-left: 0px solid transparent;
		// 	border-right: calc(var(--border-radius) * 1.25) solid transparent;
		// 	border-top: calc(var(--border-radius) * 1.25) solid var(--black);
		// 	clear: both;
		// 	z-index: -10;
		// 	transform: translateY(90%);
		// }
	}

	.bubble-ups-up-enter-active, .bubble-ups-up-leave-active {
		transition: opacity .5s var(--easing),
					transform .5s var(--easing);
	}

	.bubble-ups-up-enter {
		transform: translateY(50px);
		opacity: 0;
	}

	.bubble-ups-up-leave-to {
		opacity: 0;
	}

	.learn-more {
		display: block;
		width: 100%;
		text-align: right;
		/* font-size: .75em; */
		/* text-decoration: underline; */
		opacity: .5;
	}



</style>
